import type { JSX } from 'react';
import { CardFeatureText } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type CardFeatureTextProps =
  SliceComponentProps<Content.CardFeatureTextSlice>;

const CardFeatureTextSlice = ({ slice }: CardFeatureTextProps): JSX.Element => {
  return <CardFeatureText {...slice} />;
};

export default CardFeatureTextSlice;
